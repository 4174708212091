<template>
  <div>
    <b-modal
      id="onSubmitListOpenModel"
      no-close-on-backdrop
      cancel-variant="outline-danger"
      cancel-only
      hide-footer
      size="lg"
    >
      <div>
        <div class="text-center">
          <b
            style="font-size: 20px"
          >Do you want to
            <span v-if="radioChecked === 'PRINT'"> Print</span>
            <span v-else>Collect</span>
            this invoice?</b>
        </div>
        <div class="text-center">
          <b>Student Name: {{ studentData.name }}. Student ID:
            {{ studentData.id }}</b>
        </div>
        <div class="text-center">
          <b>Following amount will be paid for their particulars:</b>
        </div>
        <br>
      </div>
      <b-form-group label-cols-md="3">
        <b-form-radio-group
          v-model="radioChecked"
          class="mt-1"
          :options="printCashRadioButtons"
          name="radio-inline5"
          required
        />
      </b-form-group>

      <b-form-group v-if="radioChecked === 'POS'">
        <b-form-input
          v-model="reference_id"
          placeholder="Ref ID"
        />
      </b-form-group>
      <b-form-group v-if="radioChecked === 'BANK'">
        <v-select
          v-model="fintechBank"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :get-option-label="(option) => option.fintech.name"
          placeholder="Select Fintech"
          :options="fintechBankOptions"
          :reduce="(val) => val.id"
        />
      </b-form-group>

      <vue-good-table
        :columns="printCashColumns"
        :rtl="direction"
        :rows="printCashRows"
        :line-numbers="true"
      />
      <b-form-group
        label-cols-md="5"
        class="mt-5"
      >
        <b-button
          v-if="radioChecked === 'PRINT'"
          type="button"
          variant="outline-secondary"
          class="mr-1"
          @click.prevent="onPrintWithAmount"
        >

          Print
        </b-button>
        <b-button
          v-else-if="
            radioChecked === 'POS' ||
              radioChecked === collectionCoaId ||
              radioChecked === 'BANK'
          "
          type="button"
          variant="outline-secondary"
          class="mr-1"
          @click.prevent="onSubmitCollectList"
        >

          Yes
        </b-button>
        <b-button
          type="button"
          variant="outline-danger"
          class="mr-1"
          @click.prevent="noFuncOuter"
        >
          No
        </b-button>
      </b-form-group>
    </b-modal>

    <!-- On Submit Final Confirm Modal  -->
    <b-modal
      id="onSubmitConfrmModal"
      no-close-on-backdrop
      cancel-variant="outline-danger"
      cancel-only
      hide-footer
      size="lg"
    >
      <div>
        <div class="text-center">
          <b>Student Name: {{ studentData.name }}. Student ID:
            {{ studentData.id }}</b>
        </div>
        <div class="text-center">
          <b>Following amount will be paid for their particulars:</b>
        </div>
        <br>
      </div>
      <div
        v-if="radioChecked === collectionCoaId"
        style="font-size: 20px; text-align: center"
        class="mb-1"
      >
        Payment Mode : Cash
      </div>
      <div
        v-else-if="radioChecked === 'POS'"
        style="font-size: 20px; text-align: center"
        class="mb-1"
      >
        Payment Mode : POS <br>
        Ref ID : {{ reference_id }}
      </div>
      <div
        v-else-if="radioChecked === 'BANK'"
        style="font-size: 20px; text-align: center"
        class="mb-1"
      >
        Payment Mode : BANK <br>
        Fintech/Bank : {{ fintechBankName }}
      </div>
      <div v-else />

      <vue-good-table
        :columns="printCashColumns"
        :rtl="direction"
        :rows="printCashRows"
        :line-numbers="true"
      />
      <b-form-group
        label-cols-md="5"
        class="mt-5"
      >
        <b-button
          type="submit"
          variant="outline-secondary"
          class="mr-1"
          :disabled="isButtonDisabled"
          @click.prevent="confirmFunction"
        >
          Confirm
        </b-button>
        <b-button
          type="button"
          variant="outline-danger"
          class="mr-1"
          @click.prevent="onSaveCollectionGrid"
        >
          <!-- @click.prevent="onSaveCollectionGrid" -->
          No
        </b-button>
      </b-form-group>
    </b-modal>
    <!-- Transaction Print Operation -->
    <TransactionPrint
      ref="bulkMoneyReceipt"
      :money-receipt="moneyReceipt"
      :student-info="studentInfo"
      :payment-info="paymentInfo"
      :transaction-info="transactionInfo"
    />
  </div>
</template>
<script>
import {
  BModal, BFormGroup, BFormRadioGroup, BButton, BFormInput,
} from 'bootstrap-vue'
import store from '@/store/index'
import FemsToastrService from '@/@service/utils/FemsToastr.service'
import PaymentCollectionService from '@/@service/api/accounting/PaymentCollection.service'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import { generatePDF } from '@/@service/utils/InvoicePdfGenerator'
import vSelect from 'vue-select'
import LocalStorageService from '@/@service/utils/LocalStorage.service'
import TransactionPrint from '@/views/payment-collection/components/TransactionPrint.vue'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormRadioGroup,
    BButton,
    BFormInput,
    VueGoodTable,
    vSelect,
    TransactionPrint,
  },
  props: {
    studentData: {
      type: Object,
      required: true,
    },
    studentInfo: {
      type: Object,
      required: true,
    },
    isBulkCollection: {
      type: Boolean,
      required: true,
    },
    getGridData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      getPageData: JSON.parse(LocalStorageService.get('pageData')) || '',
      radioChecked: 'PRINT',
      fintechBank: null,
      fintechBankName: null,
      reference_id: null,
      collectionCoaId: 47,
      printCashRadioButtons: [
        { text: 'Payment Invoice', value: 'PRINT', is_ref: 0 },
        { text: 'Cash', value: 47, is_ref: 0 },
        { text: 'Bank', value: 'BANK', is_ref: 0 },
        { text: 'POS', value: 'POS', is_ref: 1 },
      ],
      fintechBankOptions: [],
      printCashColumns: [
        {
          label: 'Item Name',
          field: 'item_name',
        },
        {
          label: 'Amount',
          field: 'amount',
          type: 'decimal',
          tdClass: 'text-right',
          thClass: 'text-left',
        },
        {
          label: 'Adjust Amount',
          field: 'adjust_amount',
          tdClass: 'text-right',
        },
      ],
      printCashRows: [],
      submit: {
        collectionAmount: null,
        amount: '',
        ref_id: '',
      },
      isPrintInvoice: false,
      isButtonDisabled: false,
      moneyReceipt: {},
      paymentInfo: {},
      transactionInfo: {},
      isPrintDocument: false,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  methods: {

    onSubmitCollectListView(amount) {
      this.radioChecked = 'PRINT'
      this.fintechBank = null
      this.fintechBankName = null
      this.reference_id = null
      this.submit.amount = amount
      PaymentCollectionService.collectNow(this.studentData.id, {
        amount: this.submit.amount,
      })
        .then(res => {
          if (res.data.status) {
            this.printCashRows = res.data.data
          } else {
            FemsToastrService.error(res?.data.message)
          }
        })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })

      PaymentCollectionService.fintechBankData().then(res => {
        if (res.data.status) {
          //  TODO this code is for temporary purpose. when manual collection configuration be set, this will be dynamic
          this.fintechBankOptions = res.data.data.filter(x => [2, 3].includes(x.fintech_id))
        } else {
          FemsToastrService.error(res?.data.message)
        }
      })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })

      this.$bvModal.show('onSubmitListOpenModel')
    },

    onPrintWithAmount() {
      if (this.submit.amount > 0) {
        const studentData = {
          campus: this.studentInfo?.std_academic?.branch.name,
          campusAddress: this.studentInfo?.std_academic?.branch?.address,
          name: this.studentInfo?.full_name,
          studentId: this.studentInfo?.student_id,
          class: this.studentInfo?.std_academic?.student_class?.name || '',
          section: this.studentInfo?.std_academic?.student_section
            ? this.studentInfo?.std_academic?.student_section?.name
            : '',
          roll: this.studentInfo?.std_academic?.roll_no || '',
        }
        const params = {
          amount: this.submit.amount,
          collectionCoaId: this.collectionCoaId,
        }
        PaymentCollectionService.getPrintInvoiceData(this.studentInfo?.student_id, params).then(res => {
          if (res.data.status) {
            this.noFuncOuter()
            this.invoiceData = res.data.data
            this.isPrintInvoice = true
            FemsToastrService.success(res?.data.message)
            const invoiceInfo = [
              {
                studentData,
                ...this.invoiceData,
              },
            ]
            const months = [
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December',
            ]
            const date = new Date()
            generatePDF(
              this.getPageData,
              invoiceInfo,
              `${studentData.studentId}.${
                months[date.getMonth()]
              }-${date.getFullYear()}`,
            )

            // generatePDF(this.getPageData, invoiceInfo, studentData.studentId)
          } else {
            FemsToastrService.error(res?.data.message)
          }
        })
          .catch(err => {
            FemsToastrService.error(err?.message)
          })
      } else {
        FemsToastrService.error('Invalid Amount')
      }
    },

    noFuncOuter() {
      this.$bvModal.hide('onSubmitListOpenModel')
    },

    onSubmitCollectList() {
      this.isPrintInvoice = false
      if (this.radioChecked === 'POS') {
        if (!this.reference_id) {
          FemsToastrService.error('Please Insert Reference Id.')
          return
        }
      }
      if (this.radioChecked === 'BANK') {
        if (!this.fintechBank) {
          FemsToastrService.error('Please select a fintech')
          return
        }
        this.fintechBankName = this.fintechBankOptions.find(
          ({ id }) => id === this.fintechBank,
        ).fintech.name
      }
      this.noFuncOuter()
      this.$bvModal.show('onSubmitConfrmModal')
    },

    confirmFunction() {
      this.isButtonDisabled = true
      if (this.radioChecked === 'POS' || this.radioChecked === this.collectionCoaId) {
        this.isButtonDisabled = false
        FemsToastrService.error('This process is on working mode')
        return
      }

      if (this.radioChecked === 'BANK') {
        this.isButtonDisabled = true
        PaymentCollectionService.saveManualCollection({
          amount: this.submit.amount,
          fintechId: this.fintechBank,
          studentId: this.studentInfo?.student_id,
          request: 'manual',
        })
          .then(res => {
            if (res.data.status) {
              this.isButtonDisabled = false
              this.$bvModal.hide('onSubmitConfrmModal')
              this.$bvModal.hide('onSubmitListOpenModel')
              this.submit = {
                collectionAmount: null,
                amount: '',
              }
              this.fintechBank = null
              this.getGridData()
              this.$swal({
                title: 'Transaction Successful',
                html: '<b>Your transaction is successful. Press print button to print the money receipt.</b>',
                icon: 'success',
                showCancelButton: true,
                confirmButtonText: 'Print',
                cancelButtonText: 'No',
                customClass: {
                  confirmButton: 'btn btn-primary mr-1',
                  cancelButton: 'btn btn-danger',
                },
              }).then(result => {
                if (result.value) {
                  const resData = res.data.data
                  console.log(res.data)
                  console.log(res.data.data)
                  this.moneyReceipt = {
                    data: resData.charge_list,
                  }
                  this.paymentInfo = {
                    bankName: this.fintechBankName
                      ? this.fintechBankName
                      : '',
                    posRef: this.reference_id ? this.reference_id : '',
                  }
                  this.transactionInfo = {
                    id: resData.transaction_id
                      ? resData.transaction_id
                      : '',
                    time: resData.transaction_time
                      ? resData.transaction_time
                      : '',
                  }
                  setTimeout(() => {
                    this.bulkMoneyReceipt()
                  }, 500)
                }
              })
            } else {
              FemsToastrService.error(res?.data.message)
            }
          })
          .catch(err => {
            FemsToastrService.error(err?.message)
          })
      } else {
        /* this.$http
          .put(`${window.apiUrl}accounting/acc-std-charge-fee/payment-adjustment/${this.studentInfo?.student_id}`, {
            amount: this.submit.amount,
            collectionCoaId: this.collectionCoaId,
          })
          .then(res => {
            if (res.status) {
              if (res.data.status) {
                this.$bvModal.hide('onSubmitConfrmModal')
                this.$bvModal.hide('onSubmitListOpenModel')
                this.submit = {
                  collectionAmount: null,
                  amount: '',
                }
                this.reference_id = null
                FemsToastrService.success(res?.data.message)
                this.getGridData()
              } else {
                FemsToastrService.error(res?.data.message)
              }
            }
          }).catch(err => {
            FemsToastrService.error(err?.message)
          }) */
      }
    },

    bulkMoneyReceipt() {
      this.isPrintDocument = true
      this.$nextTick(() => {
        this.$refs.bulkMoneyReceipt.printDocument()
      })
    },

    onSaveCollectionGrid() {
      this.printCashRows = []
      PaymentCollectionService.saveCollectionGrid(this.studentInfo?.student_id, {
        amount: this.submit.amount,
      })
        .then(res => {
          if (res.data.status) {
            this.printCashRows = res.data.data
          } else {
            FemsToastrService.error(res?.data.message)
          }
        })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
      this.$bvModal.show('onSubmitListOpenModel')
      this.$bvModal.hide('onSubmitConfrmModal')
    },
  },
}
</script>
