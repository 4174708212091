<template>
  <div>
    <b-modal
      id="collectNowModal"
      no-close-on-backdrop
      title="Payment Collection List"
      ok-title="Yes"
      size="lg"
      hide-footer
    >
      <b-form @submit.prevent="onCollectNowList">
        <div class="text-center">
          <b>Do you want to collect this fees?</b>
        </div>
        <br>
        <div class="text-center mb-2">
          <b>Student Name: {{ studentData.name }}. Student ID:
            {{ studentData.id }}</b><br>
        </div>
        <div class="text-center">
          <b>Following amount will be paid for their particulars:</b>
        </div>
        <br>
        <b-form-group label-cols-md="5">
          <b-form-radio-group
            v-model="collectionChecked"
            class="mt-1"
            :options="collectionRadioButtons"
            name="radio-inline5"
            required
            @input="changeCollectionChecked"
          />
        </b-form-group>
        <b-form-group v-if="collectionChecked === 'POS'">
          <b-form-input
            v-model="collectionReferenceId"
            placeholder="Ref ID"
          />
        </b-form-group>
        <vue-good-table
          :columns="payNowColumns"
          :rtl="direction"
          :rows="payNowRows"
          :line-numbers="true"
        />
        <b-form-group
          label-cols-md="5"
          class="mt-5"
        >
          <b-button
            type="submit"
            variant="outline-secondary"
            class="mr-1"
          >

            Yes
          </b-button>
          <b-button
            type="button"
            variant="outline-danger"
            class="mr-1"
            @click.prevent="onCancelCollectNow"
          >
            No
          </b-button>
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- Collect Now single confirm Modal -->
    <b-modal
      id="collectNowConfirmModal"
      no-close-on-backdrop
      title="Confirm Payment Collection"
      ok-title="Yes"
      size="lg"
      hide-footer
    >
      <b-form @submit.prevent="onCollectNowConfirm">
        <div class="text-center mb-2">
          <b>Student Name: {{ studentData.name }}. Student ID:
            {{ studentData.id }}</b><br>
        </div>
        <div class="text-center">
          <b>Following amount will be paid for their particulars:</b>
        </div>
        <br>
        <div
          v-if="collectionChecked === collectionCoaId"
          style="font-size: 20px; text-align: center"
          class="mb-1"
        >
          Payment Mode : Cash
        </div>
        <div
          v-else-if="collectionChecked === 'POS'"
          style="font-size: 20px; text-align: center"
          class="mb-1"
        >
          Payment Mode : POS <br>
          Ref ID : {{ collectionReferenceId }}
        </div>
        <div v-else />
        <vue-good-table
          :columns="payNowColumns"
          :rtl="direction"
          :rows="payNowRows"
          :line-numbers="true"
        />
        <b-form-group
          label-cols-md="5"
          class="mt-5"
        >
          <b-button
            type="submit"
            variant="outline-secondary"
            class="mr-1"
            :disabled="isButtonDisabled"
          >
            Yes
          </b-button>
          <b-button
            type="button"
            variant="outline-danger"
            class="mr-1"
            @click.prevent="onCancelCollectNowInner"
          >
            No
          </b-button>
        </b-form-group>
      </b-form>
    </b-modal>
    <!-- Transaction Print Operation -->
    <TransactionPrint
      ref="moneyReceiptRef"
      :money-receipt="moneyReceipt"
      :student-info="studentInfo"
      :payment-info="paymentInfo"
      :transaction-info="transactionInfo"
    />
  </div>
</template>

<script>
import {
  BModal, BForm, BFormGroup, BFormRadioGroup, BButton, BFormInput,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import FemsToastrService from '@/@service/utils/FemsToastr.service'
import PaymentCollectionService from '@/@service/api/accounting/PaymentCollection.service'
import store from '@/store/index'
import TransactionPrint from '@/views/payment-collection/components/TransactionPrint.vue'

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormRadioGroup,
    BButton,
    VueGoodTable,
    BFormInput,
    TransactionPrint,
  },
  props: {
    studentData: {
      type: Object,
      required: true,
    },
    studentInfo: {
      type: Object,
      required: true,
    },
    isSingleCollection: {
      type: Boolean,
      required: true,
    },
    getGridData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      collectionChecked: 47,
      collectionCoaId: 47,
      payNowID: null,
      remainingAmount: 0,
      collectionRadioButtons: [
        { text: 'Cash', value: 47, is_ref: 0 },
        { text: 'POS', value: 'POS', is_ref: 1 },
      ],
      collectionReferenceId: null,
      payNowColumns: [
        {
          label: 'Item Name',
          field: 'item_name',
        },
        {
          label: 'Amount',
          field: 'amount',
          type: 'decimal',
          tdClass: 'text-right',
          thClass: 'text-left',
        },
      ],
      payNowRows: [],
      isButtonDisabled: false,
      moneyReceipt: {},
      paymentInfo: {},
      transactionInfo: {},
      fintechBankName: '',
      reference_id: '',
      isPrintDocument: false,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  methods: {

    changeCollectionChecked() {
      if (this.collectionChecked === 'POS') {
        this.collectionReferenceId = null
      }
    },

    onCollectListView(rowData, collectionCoaId) {
      this.collectionChecked = collectionCoaId
      this.payNowID = rowData.id
      PaymentCollectionService.payNow(this.studentData.id, this.payNowID).then(res => {
        if (res.data.status) {
          this.payNowRows = res.data.data
          this.getRemainingAdvance(this.studentData.id)
        } else {
          this.payNowRows = []
        }
      })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
      this.$bvModal.show('collectNowModal')
    },

    getRemainingAdvance(studentId) {
      PaymentCollectionService.getRemainingAdvance(studentId)
        .then(res => {
          if (res.data.status) {
            this.remainingAmount = res.data.data.amount
          }
        })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
    },

    onCollectNowList() {
      if (this.collectionChecked === 'POS') {
        if (!this.collectionReferenceId) {
          FemsToastrService.error('Please input reference Id')
          return
        }
      }
      this.$bvModal.show('collectNowConfirmModal')
      this.$bvModal.hide('collectNowModal')
    },

    onCancelCollectNow() {
      this.$bvModal.hide('collectNowModal')
      this.$bvModal.hide('collectNowConfirmModal')
    },

    onCollectNowConfirm() {
      this.isButtonDisabled = true
      if (this.collectionChecked === 'POS') {
        this.isButtonDisabled = false
        FemsToastrService.error('This process is on working mode')
        return
      }
      const params = {
        collectionCoaId: this.collectionCoaId,
      }
      PaymentCollectionService.singlePayNowStore(this.payNowID, params)
        .then(res => {
          if (res.data.status) {
            this.isButtonDisabled = false
            this.$bvModal.hide('collectNowConfirmModal')
            this.$bvModal.hide('collectNowModal')
            this.collectionReferenceId = null
            this.$swal({
              title: 'Transaction Successful',
              html: '<b>Your transaction is successful. Press print button to print the money receipt.</b>',
              icon: 'success',
              showCancelButton: true,
              confirmButtonText: 'Print',
              cancelButtonText: 'No',
              customClass: {
                confirmButton: 'btn btn-primary mr-1',
                cancelButton: 'btn btn-danger',
              },
            }).then(result => {
              if (result.value) {
                const resData = res.data.data
                this.moneyReceipt = {
                  data: [resData.charge_list],
                }
                this.paymentInfo = {
                  bankName: this.fintechBankName || '',
                  posRef: this.reference_id || '',
                }
                this.transactionInfo = {
                  id: resData.transaction_id || '',
                  time: resData.transaction_time || '',
                }
                setTimeout(() => {
                  this.moneyReceiptPrint()
                }, 500)
              }
            })
            this.getGridData()
          } else {
            FemsToastrService.error(res?.data.message)
          }
        })
    },

    onCancelCollectNowInner() {
      this.$bvModal.show('collectNowModal')
    },

    moneyReceiptPrint() {
      this.isPrintDocument = true
      this.$nextTick(() => {
        this.$refs.moneyReceiptRef.printDocument()
      })
    },
  },
}
</script>
