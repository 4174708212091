<template>
  <!-- Pending Transaction Task -->
  <b-modal
    id="pending-transaction-modal"
    title="Pending Transaction"
    no-close-on-backdrop
    cancel-variant="outline-danger"
    cancel-only
    hide-footer
    size="lg"
  >
    <div>
      <div class="text-center">
        <b>Student Name: {{ studentData.name }}. Student ID:
          {{ studentData.id }}</b>
      </div>
      <br>
    </div>
    <div
      v-if="transactionDetails && transactionDetails.length"
      class="mb-3"
    >
      <div class="text-center mb-2">
        <b>Following amount will be paid for their particulars:</b>
      </div>
      <app-collapse accordion>
        <div
          v-for="item in transactionDetails"
          :key="item.transaction_id"
        >
          <app-collapse-item
            :title="`Transaction ID : ${item.transaction_id}, Amount : ${item.amount}, Date : ${item.created_at}`"
          >
            <div
              v-if="item.trans_details.dues && item.trans_details.dues.length"
            >
              <h4>Dues List :</h4>
              <div>
                <table
                  id="printTable"
                  width="100%"
                  class="due_table"
                >
                  <thead>
                    <tr>
                      <th>Chart of ID</th>
                      <th class="right-align">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="due_item in item.trans_details.dues"
                    :key="due_item.charge_id"
                  >
                    <tr>
                      <td>{{ due_item.label }}</td>
                      <td class="right-align">
                        {{ due_item.amount }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <br>
            <div
              v-if="
                item.trans_details.advance &&
                  item.trans_details.advance.length
              "
            >
              <h4>Advance List :</h4>
              <div>
                <table
                  id="printTable"
                  width="100%"
                  class="advance_table"
                >
                  <thead>
                    <tr>
                      <th>Chart of ID</th>
                      <th class="right-align">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="advance_item in item.trans_details.advance"
                    :key="advance_item.coa_id"
                  >
                    <tr>
                      <td>{{ advance_item.label.gl_name }}</td>
                      <td class="right-align">
                        {{ advance_item.amount }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </app-collapse-item>
        </div>
      </app-collapse>
    </div>
    <div
      v-else
      class="text-center mt-0 mb-5"
    >
      <span
        style="font-weight: bold; color: #bb0000"
      >There is no Pending Transaction</span>
    </div>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import PaymentCollectionService from '@/@service/api/accounting/PaymentCollection.service'
import FemsToastrService from '@/@service/utils/FemsToastr.service'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BModal,
    AppCollapse,
    AppCollapseItem,
  },

  props: {
    studentData: {
      type: Object,
      required: true,
    },
    isSetPriorityModal: {
      type: Boolean,
    },
  },
  data() {
    return {
      transactionDetails: [],
    }
  },
  methods: {
    onModalOpen() {
      this.transactionDetails = []
      PaymentCollectionService.pendingTransaction(this.studentData.id)
        .then(res => {
          if (res.data.status) {
            this.transactionDetails = res.data.data
            this.$bvModal.show('pending-transaction-modal')
            return
          }
          FemsToastrService.info(res.data.message)
        })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
    },
  },
}

</script>
