import axiosIns from '@/libs/axios'

export default {
  getRemainingAdvance: (studentId => axiosIns.get(`accounting/acc-std-charge-fee/remaining-advance/${studentId}`)),
  getStudentList: (search => axiosIns.get(`accounting/acc-std-charge-fee/students/${search}`)),
  getInstallment: ((sessionId, branchId, classId) => axiosIns.get(`accounting/acc-std-charge-fee/payment-cycle/${sessionId}/${branchId}/${classId}`)),
  getFeeList: (() => axiosIns.get('accounting/coa-mapping/std-fee-coa')),
  getChargesData: (studentID => axiosIns.get(`accounting/acc-std-charge-fee/read/${studentID}`)),
  getPrintInvoiceData: ((studentID, data) => axiosIns.put(`accounting/acc-std-charge-fee/print-payment-invoice/${studentID}`, data)),
  onDeleteCharge: ((chargeId, note) => axiosIns.put(`accounting/acc-std-charge-fee/delete/${chargeId}`, note)),
  onSave: (data => axiosIns.post('accounting/acc-std-charge-fee', data)),
  payNow: ((studentId, payId) => axiosIns.get(`accounting/acc-std-charge-fee/collect-now-single/${studentId}/${payId}`)),
  singlePayNowStore: ((payId, data) => axiosIns.put(`accounting/acc-std-charge-fee/collect-now-single/${payId}`, data)),
  getConcession: (coaId => axiosIns.get(`accounting/acc-std-charge-fee/concession-coa-list/${coaId}`)),
  saveConcession: ((concessionUpdateID, data) => axiosIns.put(`accounting/acc-std-charge-fee/concession/${concessionUpdateID}`, data)),
  saveAdjustment: ((adjUpdateID, data) => axiosIns.put(`accounting/acc-std-charge-fee/adjustment/${adjUpdateID}`, data)),
  collectNow: ((studentId, amount) => axiosIns.put(`accounting/acc-std-charge-fee/collect-now/${studentId}`, amount)),
  fintechBankData: (() => axiosIns.get('accounting/acc-std-charge-fee/get-fintech-data-for-bank')),
  saveCollectionGrid: ((studentId, amount) => axiosIns.put(`accounting/acc-std-charge-fee/collect-now/${studentId}`, amount)),
  saveManualCollection: (data => axiosIns.post('payment/manual-collection', data)),
  savePaymentAdjustment: ((studentId, data) => axiosIns.put(`accounting/acc-std-charge-fee/payment-adjustment/${studentId}`, data)),
  customPaymentPriority: (studentId => axiosIns.get(`accounting/acc-std-charge-fee/custom-priority/${studentId}`)),
  customPaymentPriorityStore: ((studentId, data) => axiosIns.put(`accounting/acc-std-charge-fee/custom-priority/${studentId}`, data)),
  pendingTransaction: (studentId => axiosIns.get(`accounting/acc-std-charge-fee/pending-transaction/${studentId}`)),
}
