<template>
  <div>
    <!-- Adjustment Modal -->
    <b-modal
      id="adj-modal"
      no-close-on-backdrop
      title="Fee Adjustment"
      ok-title="Process"
      ok-variant="outline-primary"
      cancel-variant="outline-danger"
      @ok.prevent="adjustmentReason"
    >
      <b-form @submit.prevent>
        <div class="text-center">
          <b>Student Name: {{ studentData.name }}. Student ID:
            {{ studentData.id }}</b>
        </div>
        <br>
        <div class="text-center mb-2">
          <b>Fee Head:</b> {{ adjFeeHead }}<br>
          <b> Amount:</b> {{ adjAmount }}
        </div>
        <!-- Operation Type  -->
        <b-form-group
          label="Operation Type"
          label-cols-md="4"
          invalid-feedback="Operation Type is required"
          :state="adjDataOperationTypeState"
        >
          <v-select
            v-model="adjData.operationType"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            placeholder="Select Operation Type"
            :options="adjDataOperationTypeOptions"
            :reduce="(val) => val.value"
            :state="adjDataOperationTypeState"
          />

        </b-form-group>
        <!-- Amount -->
        <b-form-group
          label="Amount"
          label-cols-md="4"
          invalid-feedback="Amount is required"
          :state="adjDataAmountState"
        >
          <b-form-input
            v-model="adjData.amount"
            placeholder="Amount"
            :state="adjDataAmountState"
            required
            type="number"
          />
        </b-form-group></b-form>
    </b-modal>

    <!-- Adjustment Modal Reason -->
    <b-modal
      id="adj-modal-reason"
      no-close-on-backdrop
      title="Adjustment Reason"
      ok-title="Yes"
      cancel-title="No"
      ok-variant="outline-success"
      cancel-variant="outline-danger"
      @ok.prevent="adjustmentStore"
    >
      <b-form @submit.prevent>
        <div class="text-center">
          <b>Do you want to process this concession??</b>
        </div>
        <br>
        <div class="text-center mb-2">
          <b>Student Name: {{ studentData.name }}. Student ID:
            {{ studentData.id }}</b>
        </div>
        <!-- Reason -->
        <b-row>
          <b-col>
            <b-form-group
              invalid-feedback="Reason is required"
              :state="adjDataReasonState"
            >
              <b-form-textarea
                id="textarea"
                v-model="adjData.reason"
                placeholder="Reason (Require)"
                rows="3"
                max-rows="6"
                :state="adjDataReasonState"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>

  </div>
</template>
<script>

import {
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea,
  VBModal,
} from 'bootstrap-vue'
import 'vue-good-table/dist/vue-good-table.css'
import vSelect from 'vue-select'
// import store from '@/store/index'
import FemsToastrService from '@/@service/utils/FemsToastr.service'
// import LocalStorageService from '@/@service/utils/LocalStorage.service'
import PaymentCollectionService from '@/@service/api/accounting/PaymentCollection.service'

export default {
  components: {
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BFormTextarea,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    studentData: {
      type: Object,
      required: true,
    },
    getGridData: {
      type: Function,
      required: true,
    },
    getRemainingAdvance: {
      type: Function,
      required: true,
    },
    isAdjustmentModal: {
      type: Boolean,
      required: true,
    },
    remainingAmount: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      adjData: {
        operationType: '',
        amount: '',
        reason: '',
      },
      adjDataOperationTypeOptions: [
        {
          name: 'Add',
          value: 'add',
        },
        {
          name: 'Subtract',
          value: 'subtract',
        },
      ],
      adjDataOperationTypeState: '',
      adjDataAmountState: '',
      adjDataReasonState: '',
      adjFeeHead: '',
      adjAmount: '',
    }
  },
  methods: {
    // adj Functions
    adjustmentReset() {
      this.adjData = {
        amount: '',
        reason: '',
      }
      this.isAdjReason = false

      this.adjDataOperationTypeState = null
      this.adjDataAmountState = null
      this.adjDataReasonState = null

      this.adjUpdateID = null
    },

    adjustmentValidation() {
      this.adjDataOperationTypeState = null
      this.adjDataAmountState = null

      if (this.adjData.operationType.length < 1) {
        this.adjDataOperationTypeState = false
        return false
      }
      if (this.adjData.amount === '' || this.adjData.amount === null) {
        this.adjDataAmountState = false
        return false
      }
      return true
    },

    adjustmentReasonValidation() {
      this.adjDataReasonState = null
      if (this.adjData.reason === '' || this.adjData.reason === null) {
        this.adjDataReasonState = false
        return false
      }
      return true
    },

    onAdjustment(rowData) {
      if (this.remainingAmount && this.remainingAmount > 0) {
        this.adjDataOperationTypeOptions.push({ value: 'adj_adv', name: 'Adjust from Advance' })
      }
      this.adjData.amount = null
      this.adjData.reason = null
      this.adjData.operationType = []

      this.adjDataOperationType = null
      this.adjUpdateID = rowData.id
      this.adjFeeHead = rowData.item_name
      this.adjAmount = rowData.amount
      this.$bvModal.show('adj-modal')
      this.isAdjReason = true
    },

    adjustmentReason() {
      if (!this.adjustmentValidation()) {
        return
      }
      this.$bvModal.show('adj-modal-reason')
    },

    adjustmentStore() {
      if (!this.adjustmentReasonValidation()) {
        return
      }
      const paramsData = {
        amount: this.adjData.amount,
        note: this.adjData.reason,
        adjustment: this.adjData.operationType,
      }
      PaymentCollectionService.saveAdjustment(this.adjUpdateID, paramsData).then(res => {
        if (res.data.status) {
          FemsToastrService.success(res?.data.message)
          this.$bvModal.hide('adj-modal')
          this.$bvModal.hide('adj-modal-reason')
          this.adjUpdateID = null
          this.getGridData()
          this.getRemainingAdvance(this.studentData.id)
        } else {
          FemsToastrService.error(res?.data.message)
        }
      })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
    },
  },
}
</script>
