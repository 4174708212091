<template>
  <section
    v-if="invoiceData && studentInfo"
    class="invoice-preview-wrapper"
  >
    <div id="exportArea">
      <div class="row page-break Justify-content-center">
        <div
          v-for="invoiceCopy in invoiceCopies"
          :key="invoiceCopy"
          class="col-4"
        >
          <div class="invoice-container">
            <!-- Header -->
            <div class="invoice-padding mt-auto pb-0 mb-1">
              <div class="d-flex justify-content-center flex-md-row flex-column invoice-spacing mt-0">
                <!-- Header: Left Content -->
                <div class="text-center">
                  <div
                    class="logo-wrapper"
                    :style="{ marginBottom: '0px' }"
                  >
                    <b-img
                      fluid
                      :src="cmsData.favicon"
                      alt="Fems"
                      :style="{ height: '40px' }"
                      class="float-left"
                    />
                    <b>
                      {{ cmsData.name }}
                    </b>
                  </div>
                  <p
                    v-if="studentInfo.std_academic.branch.name && studentInfo.std_academic.branch.address"
                    class="card-text mb-25"
                    :style="{lineHeight:'10px'}"
                  >
                    {{ studentInfo.std_academic.branch.name || '' +','+studentInfo.std_academic.branch.address || '' }}
                  </p>
                  <p
                    class="card-text mb-25"
                    :style="{lineHeight:'10px'}"
                  >
                    Payment Invoice ({{ invoiceCopy }} copy) for {{ invoiceData.invoiceInfo.paymentCycleName }}
                  </p>
                  <p
                    class="card-text mb-0"
                    :style="{lineHeight:'10px'}"
                  >
                    Last date of payment (without Fine): <b>{{ invoiceData.invoiceInfo.paymentLastDate }}</b>
                  </p>
                </div>

              </div>
            </div>
            <table id="printTable">
              <tr>
                <td>
                  <span class="m-left-text">Name</span>
                </td>
                <td colspan="3">
                  <b class="m-left-text"> {{ studentInfo.full_name }}</b>
                </td>
              </tr>
              <tr>
                <td>
                  <span class="m-left-text">Student Id</span>
                </td>
                <td> <b class="m-left-text">{{ studentInfo.student_id }}</b></td>
                <td><span class="m-left-text">Class</span></td>
                <td> <b class="m-left-text">{{ studentInfo.std_academic && studentInfo.std_academic.student_class && studentInfo.std_academic.student_class.name ? studentInfo.std_academic.student_class.name : "" }}</b></td>
              </tr>
              <tr>
                <td>
                  <span class="m-left-text">Section</span>
                </td>
                <td> <b class="m-left-text">{{ studentInfo.std_academic && studentInfo.std_academic.student_section && studentInfo.std_academic.student_section.name ? studentInfo.std_academic.student_section.name : "" }}</b></td>
                <td>
                  <span class="m-left-text">Roll</span>
                </td>
                <td> <b class="m-left-text">{{ studentInfo.std_academic && studentInfo.std_academic.roll_no ? studentInfo.std_academic.roll_no : "" }}</b></td>
              </tr>
            </table>
            <!-- Invoice Description: Table -->
            <table
              id="printTable"
              class="due-table"
            >
              <thead class="text-center">
                <tr>
                  <th scope="col">
                    SL
                  </th>
                  <th scope="col">
                    Description
                  </th>
                  <th scope="col">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(invoice,index) in invoiceData.invoiceData"
                  :key="invoice.id"
                >
                  <th
                    scope="row"
                    class="text-center"
                  >
                    {{ ++index }}
                  </th>
                  <td>
                    <span class="m-left-text">{{ invoice.item_name }}</span>
                  </td>
                  <td class="text-right">
                    <span class="m-left-text m-right-text">{{ invoice.amount }}</span>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th
                    colspan="2"
                    class="text-right"
                  >
                    <span class="m-left-text m-right-text">
                      Total</span>
                  </th>
                  <th
                    scope="col"
                    class="text-right"
                  >
                    <span class="m-right-text"> {{ invoiceData.amountTotal }}</span>
                  </th>
                </tr>
              </tfoot>
            </table>
            <!-- Note -->
            <div class="invoice-padding mb-auto pt-0">
              <span
                v-if="invoiceData.invoiceFooter.note"
                class="font-weight-bold"
              >Note: </span>
              <p :style="{marginTop:'5px', marginBottom:'0px'}">
                <small
                  class="note-line"
                  v-html="invoiceData.invoiceFooter.note"
                />
              </p>
            </div>
          </div>
          <!-- Invoice Footer -->
          <div class="invoice-footer">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="7"
                class="mt-md-0 mt-3"
              >
                <div
                  v-if="invoiceData.invoiceFooter.printedDate"
                  class="mb-0"
                >
                  <b>Print Date</b>
                  <span class="ml-text1">: {{ invoiceData.invoiceFooter.printedDate || "" }} </span>
                </div>
                <div
                  v-if="invoiceData.invoiceFooter.printedBy"
                  class="mb-0"
                >
                  <b>Printed By</b>
                  <span class="ml-text2">: {{ invoiceData.invoiceFooter.printedBy || '' }} </span>
                </div>
                <div
                  v-if="invoiceData.invoiceFooter.poweredBy"
                  class="mb-0"
                >
                  <b>Powered BY</b>
                  <span class="ml-text3">: {{ cmsData.powered_by || "fitl.com.bd" }} </span>
                </div>
              </b-col>
              <!-- Col: Total -->
              <b-col
                cols="12"
                md="5"
                class="mt-md-0 justify-content-end"
              >
                <div class="invoice-total-wrapper border-dark text-center">
                  <div v-if="invoiceData.invoiceFooter.employeeInfo">
                    <b-img
                      v-if="invoiceData.invoiceFooter.signature"
                      fluid
                      :src="invoiceData.invoiceFooter.signature"
                      :style="{ height: '30px',marginBottom:0 }"
                      alt="Fems"
                      class="float-center"
                    />
                    <p
                      v-else
                      :style="{marginBottom:0}"
                    >
                      (Signature)
                    </p>
                    <small :style="{lineHeight:'5px',fontSize:'9px'}"><b>{{ invoiceData.invoiceFooter.employeeInfo.employee_name || "" }}</b></small><br>
                    <small :style="{lineHeight:'5px',fontSize:'9px'}">{{ invoiceData.invoiceFooter.employeeInfo.designation || "" }}</small><br>
                    <small :style="{lineHeight:'5px',fontSize:'9px'}">{{ invoiceData.invoiceFooter.employeeInfo.department || "" }}</small>
                  </div>
                  <p
                    v-else
                    :style="{marginBottom:0,padding:'12px'}"
                  >
                    (Authority Signature)
                  </p>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import {
  BRow, BCol,
  VBToggle, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import PDFGenerator from '@/@service/utils/PDFGenerator'
import LocalStorageService from '@/@service/utils/LocalStorage.service'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BImg,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    invoiceData: Object,
    // eslint-disable-next-line vue/require-default-prop
    studentInfo: Object,
  },
  data() {
    return {
      cmsData: JSON.parse(LocalStorageService.get('pageData')) || '',
      totalAmount: 0,
      invoiceCopies: ['Bank', 'School', 'Student'],

    }
  },

  created() {
    if (!this.cmsData) {
      LocalStorageService.clear()
      this.$router.push({ name: 'auth-login' })
    }
  },
  methods: {
    // PDF generator using node package jspdf
    generatePdf() {
      const content = document.getElementById('exportArea')
      content.classList.remove('d-none')
      const paperOrientation = 'l'
      const paperSize = 'A4'
      PDFGenerator.generatePDF(content, paperOrientation, paperSize)
      content.classList.add('d-none')
    },
    // Print invoice using window.print() method javascript default
    printInvoice() {
      const content = document.getElementById('exportArea')
      content.classList.remove('d-none')
      window.print()
      content.classList.add('d-none')
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  @page {
    size: landscape;
    margin: 0;
  }
  .page-break {
    page-break-after: always;
  }
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .btn-scroll-to-top {
    display: none;
  }
  .invoice-preview {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
    display: none;
  }
  .customizer-toggle {
    display: none !important;
  }
  div.invoice-footer {
    position: fixed;
    bottom: 2.5rem !important;
  }

  .note-line p {
    line-height: 5px !important;
    font-size: 10px !important;
  }
}
#printTable {
  border: 1px solid black;
}
#printTable td,
th {
  border: 1px solid black;
}
#printTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 5px;
}
#exportArea {
  background: transparent;
  font-size: 10px;
  font-style: normal;
  color: black;
  padding: 8px;
  width: 1070px;
  height: 740px;
  //   background:white
}
.ml-text1 {
  margin-left: 12px !important;
}
.ml-text2 {
  margin-left: 11px !important;
}
.ml-text3 {
  margin-left: 3px !important;
}
.note-line p {
  line-height: 5px !important;
  font-size: 10px !important;
}
td {
  margin-left: 10px;
}
.m-left-text {
  margin-left: 10px !important;
}
.m-right-text {
  margin-right: 10px !important;
}
</style>
