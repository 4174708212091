<template>
  <b-modal
    id="custom-payment-modal"
    title="Custom Payment"
    ok-title="Save"
    ok-variant="outline-success"
    cancel-variant="outline-danger"
    no-close-on-backdrop
    @ok.prevent="customPaymentPriorityStore"
    @ok.cancel="onCancelModal"
    @ok.close="onCancelModal"
  >
    <div v-if="customPaymentRows.length === 0">
      <div class="text-center">
        <b>There is no custom payment data</b>
      </div>
      <br>
    </div>
    <div v-else>
      <div class="text-center">
        <b>Please drag and sort the list before saving</b>
      </div>
      <br>
      <b-form @submit.prevent>
        <draggable
          :list="customPaymentRows"
          @start="drag = true"
          @end="drag = false"
        >
          <transition-group>
            <li
              v-for="element in customPaymentRows"
              :key="element.id"
              class="dragable-class"
            >
              {{ element.item_name }} ({{ element.amount }})
              <span class="right-span">
                <div class="dot" />
                <div class="dot" />
                <div class="dot" />
              </span>
            </li>
          </transition-group>
        </draggable>
      </b-form>
    </div>
  </b-modal>

</template>

<script>
import draggable from 'vuedraggable'
import { BForm, VBModal } from 'bootstrap-vue'
import FemsToastrService from '@/@service/utils/FemsToastr.service'
import PaymentCollectionService from '@/@service/api/accounting/PaymentCollection.service'

export default {
  components: {
    BForm,
    draggable,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    studentData: {
      type: Object,
      required: true,
    },
    isSetPriorityModal: {
      type: Boolean,
      required: true,
    },

  },
  data() {
    return {
      customPaymentRows: [],
      drag: false,
    }
  },
  methods: {

    onCustomPaymentPriority() {
      this.$bvModal.show('custom-payment-modal')
      PaymentCollectionService.customPaymentPriority(this.studentData.id)
        .then(res => {
          if (res.data.status) {
            this.customPaymentRows = res.data.data
          } else {
            this.customPaymentRows = []
          }
        })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
    },

    customPaymentPriorityStore() {
      if (this.customPaymentRows.length === 0) {
        FemsToastrService.error('There is no custom payment data')
        return
      }

      // eslint-disable-next-line prefer-const
      let ids = []
      for (let i = 0; i < this.customPaymentRows.length; i += 1) {
        ids.push({
          id: this.customPaymentRows[i].id,
          sorting: i + 1,
        })
      }

      PaymentCollectionService.customPaymentPriorityStore(this.studentData.id, {
        customPriority: JSON.stringify(ids),
      })
        .then(res => {
          if (res.data.status) {
            FemsToastrService.success(res?.data.message)
            this.$bvModal.hide('custom-payment-modal')
            this.$emit('update:isSetPriorityModal', false)
          } else {
            FemsToastrService.error(res?.data.message)
          }
        })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
    },
    onCancelModal() {
      this.$emit('update:isSetPriorityModal', false)
      this.$bvModal.hide('custom-payment-modal')
    },

  },
}
</script>
