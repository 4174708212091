<template>
  <div>
    <b-modal
      id="add-fee-modal"
      no-close-on-backdrop
      title="Add Fee"
      ok-title="Add"
      ok-variant="outline-primary"
      cancel-variant="outline-danger"
      @ok.prevent="onAddFeeReason"
      @cancel.prevent="onCancelModal"
      @close.prevent="onCancelModal"
    >
      <b-form @submit.prevent>
        <!-- installment/month  -->
        <b-form-group
          label="Installment/Month"
          label-cols-md="4"
          invalid-feedback="Installment/Month is required"
          :state="addFeeDataInstallmentMonthState"
        >
          <v-select
            v-model="addFeeData.installmentMonth"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="cycle_name"
            placeholder="Select Installment/Month"
            :options="addFeeDataInstallmentMonthOptions"
            :reduce="(val) => val.id"
            :state="addFeeDataInstallmentMonthState"
          />
        </b-form-group>

        <!-- Fee Head -->
        <b-form-group
          label="Fee Head"
          label-cols-md="4"
          invalid-feedback="Fee Head is required"
          :state="addFeeDataFeeHeadState"
        >
          <v-select
            v-model="addFeeData.feeHead"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="gl_name"
            placeholder="Select Fee Head"
            :options="addFeeDataFeeHeadOptions"
            :reduce="(val) => val.id"
            :state="addFeeDataFeeHeadState"
          />
        </b-form-group>

        <!-- Amount -->
        <b-row>
          <b-col>
            <b-form-group
              label="Amount"
              label-cols-md="4"
              invalid-feedback="Amount is required"
              :state="addFeeDataAmountState"
            >
              <b-form-input
                v-model="addFeeData.amount"
                placeholder="Amount"
                :state="addFeeDataAmountState"
                required
                type="number"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <!-- Fee Modal Reason -->
    <b-modal
      id="add-fee-modal-reason"
      title="Fee Reason"
      no-close-on-backdrop
      ok-title="Yes"
      cancel-title="No"
      ok-variant="outline-success"
      cancel-variant="outline-danger"
      @ok.prevent="onSaveCharge"
    >
      <b-form @submit.prevent>
        <div class="text-center">
          <b>Do you want to Add Fee?</b>
        </div>
        <br>
        <div class="text-center mb-2">
          <b>Student Name: {{ studentData.name }}. Student ID:
            {{ studentData.id }}</b>
        </div>
        <!-- Reason -->
        <b-row>
          <b-col>
            <b-form-group
              invalid-feedback="Reason is required"
              :state="addFeeDataReasonState"
            >
              <b-form-textarea
                id="textarea"
                v-model="addFeeData.reason"
                placeholder="Reason (Require)"
                rows="3"
                max-rows="6"
                :state="addFeeDataReasonState"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>
<script>

import {
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea,
  VBModal,
} from 'bootstrap-vue'
import 'vue-good-table/dist/vue-good-table.css'
import vSelect from 'vue-select'
import FemsToastrService from '@/@service/utils/FemsToastr.service'
import PaymentCollectionService from '@/@service/api/accounting/PaymentCollection.service'

export default {
  components: {
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BFormTextarea,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    studentData: {
      type: Object,
      required: true,
    },
    getGridData: {
      type: Function,
      required: true,
    },
    isFeeModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      addFeeDataReasonState: null,
      isFeeReason: false,
      addFeeData: {
        installmentMonth: '',
        feeHead: '',
        amount: '',
        reason: '',
      },
      addFeeDataFeeHeadOptions: [],
      addFeeDataInstallmentMonthState: null,
      addFeeDataInstallmentMonthOptions: [],
      addFeeDataFeeHeadState: null,
      addFeeDataAmountState: null,
    }
  },
  created() {
    this.getFeeHead()
    this.getInstallmentMonth()
  },
  methods: {
    // Chart of account api
    getFeeHead() {
      this.feesReset()
      PaymentCollectionService.getFeeList().then(res => {
        if (res.data.status) {
          this.addFeeDataFeeHeadOptions = res.data.data
        } else {
          this.addFeeDataFeeHeadOptions = []
        }
      })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
    },
    // Installment api
    getInstallmentMonth() {
      PaymentCollectionService.getInstallment(this.studentData.sessionId, this.studentData.branchId, this.studentData.classId)
        .then(res => {
          if (res.data.status) {
            this.addFeeDataInstallmentMonthOptions = res.data.data
            return
          }
          this.addFeeDataInstallmentMonthOptions = []
        })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
    },

    onAddFeeReason() {
      if (!this.addFeeValidation()) {
        return
      }
      this.addFeeDataReasonState = null
      this.$bvModal.show('add-fee-modal-reason')
      this.isFeeReason = true
    },
    onCancelModal() {
      this.$emit('update:isFeeModal', false)
      this.$bvModal.hide('add-fee-modal')
    },

    onSaveCharge() {
      if (!this.addFeeResonValidation()) {
        return
      }
      const params = {
        studentId: this.studentData.id,
        secAcaSessionId: this.studentData.sessionId,
        paymentCycleChildId: this.addFeeData.installmentMonth,
        coaId: this.addFeeData.feeHead,
        amount: this.addFeeData.amount,
        note: this.addFeeData.reason,
      }
      PaymentCollectionService.onSave(params).then(res => {
        if (res.data.status) {
          FemsToastrService.success(res?.data.message)
          this.$bvModal.hide('add-fee-modal')
          this.$bvModal.hide('add-fee-modal-reason')
          this.$emit('update:isFeeModal', false)
          this.getGridData()
        } else {
          FemsToastrService.error(res?.data.message)
        }
      })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
    },
    feesReset() {
      this.addFeeData = {
        installmentMonth: '',
        feeHead: '',
        amount: '',
        reason: '',
      }
      this.isFeeReason = false

      this.addFeeDataFeeHeadOptions = []
      this.addFeeDataInstallmentMonthOptions = []

      this.addFeeDataInstallmentMonthState = null
      this.addFeeDataFeeHeadState = null
      this.addFeeDataAmountState = null
      this.addFeeDataReasonState = null
    },

    addFeeValidation() {
      this.addFeeDataInstallmentMonthState = null
      this.addFeeDataFeeHeadState = null
      this.addFeeDataAmountState = null
      if (this.addFeeData.installmentMonth.length < 1) {
        this.addFeeDataInstallmentMonthState = false
        return false
      }
      if (this.addFeeData.feeHead.length < 1) {
        this.addFeeDataFeeHeadState = false
        return false
      }
      if (this.addFeeData.amount === '' || this.addFeeData.amount === null) {
        this.addFeeDataAmountState = false
        return false
      }
      return true
    },

    addFeeResonValidation() {
      this.addFeeDataReasonState = null
      if (this.addFeeData.reason === '' || this.addFeeData.reason === null) {
        this.addFeeDataReasonState = false
        return false
      }
      return true
    },

  },
}
</script>
