<template>
  <section class="invoice-preview-wrapper">
    <b-card>
      <div class="d-flex justify-content-between">
        <p class="card-title">
          Payment Collection
        </p>
        <b-link
          variant="outline-info mx-2"
          type="button"
          @click="onActivityLog"
        >
          Activity log
        </b-link>
      </div>
      <search
        :on-reset="onReset"
        :on-select-student="onSelectStudent"
        :student-i-d="studentID"
      />
      <!-- Text and Gridview  -->
      <div v-if="is_show">
        <div class="m-2">
          <b>
            Student Name: {{ studentData.name }} | Student ID:
            {{ studentData.id }} | Campus: {{ studentData.campusName }} | Class:
            {{ studentData.className }} | Session: {{ studentData.sessionName }}
          </b>
          <p class="mt-1">
            Total due amount: <b>{{ totalDue.toFixed(2) }}</b><br>
            Remaining Advance amount:
            <b style="color: green; font-size: 20px">{{ remainingAmount }}</b>
          </p>
        </div>

        <div class="custom-search d-flex justify-content-between">
          <b-form-group>
            <b-button
              type="submit"
              variant="outline-secondary"
              class="mr-1"
              @click.prevent="onAddFee"
            >
              Add Fee
            </b-button>
            <b-button
              type="submit"
              variant="outline-secondary"
              class="mr-1"
              @click.prevent="pendingTransactionDetails"
            >
              <!--              -->
              Pending Transaction
            </b-button>
          </b-form-group>
          <!-- Session Filter Dropdown -->
          <b-form-group class="">
            <b-button
              type="submit"
              variant="outline-primary"
              class="mr-1"
              @click.prevent="onCustomPaymentPriority"
            >
              Set Priority
            </b-button>
            <b-button
              type="submit"
              variant="outline-primary"
              class="mr-1"
              @click.prevent="onPrintStudentCharge"
            >
              Print Payment invoice
            </b-button>
          </b-form-group>
        </div>
        <!-- table for payment cycle  -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          :line-numbers="true"
          theme="my-theme"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">
              <span class="d-flex">
                <b-button
                  class="mr-1 px-1"
                  variant="outline-primary"
                  @click="getConcession(props.row)"
                >Concession</b-button>
                <b-button
                  class="px-1 mr-1"
                  variant="outline-success"
                  @click="onAdjustment(props.row)"
                >Adj</b-button>
                <b-button
                  class="px-1 mr-1"
                  variant="outline-danger"
                  @click="onDelete(props.row)"
                >Delete</b-button>
                <b-button
                  v-if="props.row.priority === null"
                  class="mr-1 px-1"
                  variant="outline-info"
                  @click="onPayNow(props.row)"
                >Collect Now</b-button>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10', '20', '30', '40', '50']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div class="d-flex">
                <div class="mr-2">
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="
                      (value) => props.pageChanged({ currentPage: value })
                    "
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
                <div
                  slot="table-actions"
                  class="mt-1"
                >
                  <b-button
                    class="mr-1 px-1"
                    variant="outline-primary"
                    @click="refreshGrid()"
                  >
                    <RefreshCwIcon />
                  </b-button>
                </div>
              </div>
            </div>
          </template>
        </vue-good-table>

        <!-- Payment Collection -->
        <div class="mt-2">
          <div class="d-flex justify-content-between">
            <span class="ml-1"><b>Payment Collection</b></span>
          </div>

          <div class="custom-search d-flex mt-1">
            <!-- Textbox for ID  -->
            <b-form-group
              invalid-feedback="Amount is required"
              :state="submitAmountState"
              class="pr-0 col-lg-2 col-md-3"
            >
              <b-form-input
                v-model="submit.amount"
                placeholder="Amount"
                type="number"
              />
            </b-form-group>

            <b-form-group
              label-sr-only
              label="Buttons"
              class="ml-5"
            >
              <b-button
                type="submit"
                variant="outline-success"
                class="mr-1"
                @click.prevent="onSubmit"
              >
                Submit
              </b-button>
            </b-form-group>
          </div>
        </div>
      </div>
    </b-card>

    <!-- Add fee modal -->
    <fee-modal
      v-if="isFeeModal"
      :student-data="studentData"
      :get-grid-data="getGridData"
      :is-fee-modal.sync="isFeeModal"
    />

    <!-- Concession modal -->
    <concession-modal
      v-if="isConcessionModal"
      ref="concessionModal"
      :student-data="studentData"
      :get-grid-data="getGridData"
      :is-concession-modal.sync="isConcessionModal"
    />

    <!-- Adjustment Modal -->
    <adjustment-modal
      v-if="isAdjustmentModal"
      ref="adjustmentModal"
      :student-data="studentData"
      :get-grid-data="getGridData"
      :remaining-amount="remainingAmount"
      :get-remaining-advance="getRemainingAdvance"
      :is-adjustment-modal.sync="isAdjustmentModal"
    />

    <!-- Set priority Custom payment Modal -->
    <set-priority-modal
      v-if="isSetPriorityModal"
      ref="priorityModal"
      :student-data="studentData"
      :is-set-priority-modal.sync="isSetPriorityModal"
    />

    <!-- Collect Now Operation  -->
    <single-collection
      v-if="isSingleCollection"
      ref="singleCollection"
      :student-data="studentData"
      :student-info="studentInfo"
      :get-grid-data="getGridData"
      :is-single-collection.sync="isSingleCollection"
    />

    <!-- On Submit Collect  -->
    <bulk-collection
      v-if="isBulkCollection"
      ref="BulkCollection"
      :student-data="studentData"
      :student-info="studentInfo"
      :get-grid-data="getGridData"
      :is-bulk-collection.sync="isBulkCollection"
    />

    <!-- Pending transactions -->
    <pending-transaction-modal
      v-if="isPendingTransactionModal"
      ref="pendingTransactionModal"
      :student-data="studentData"
      :transaction-details="isPendingTransactionModal"
    />

    <!--    Delete Button Operation - Show Reason Modal-->
    <b-modal
      id="delete-reason-model"
      no-close-on-backdrop
      title="Delete Reason"
      ok-title="Yes"
      cancel-title="No"
      ok-variant="outline-success"
      cancel-variant="outline-danger"
      @ok.prevent="onDeleteCharge"
    >
      <b-form @submit.prevent>
        <div class="text-center">
          <b>Do you want to Delete??</b>
        </div>
        <br>
        <div class="text-center mb-2">
          <b>Student Name: {{ studentData.name }}. Student ID:
            {{ studentData.id }}</b>
        </div>

        <!-- Reason -->
        <b-row>
          <b-col>
            <b-form-group
              invalid-feedback="Reason is required"
              :state="deleteReasonState"
            >
              <b-form-textarea
                id="textarea"
                v-model="deleteReason"
                placeholder="Reason (Require)"
                rows="3"
                max-rows="6"
                :state="deleteReasonState"
              />
            </b-form-group>
          </b-col>
        </b-row>

      </b-form>
    </b-modal>
    <!--    delete reason-->

    <!-- Audit Activity -->
    <activity-modal
      v-if="activityModal"
      ref="activityModal"
      :tag="tag"
    />
  </section>
</template>

<script>
import {
  BCard,
  BPagination,
  BForm,
  BFormGroup,
  BFormSelect,
  // VBModal,
  BButton,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea, BLink,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import { RefreshCwIcon } from 'vue-feather-icons'
import Invoice from '@/views/payment-collection/components/Invoice.vue'
import store from '@/store/index'
import FemsToastrService from '@/@service/utils/FemsToastr.service'
import { generatePDF } from '@/@service/utils/InvoicePdfGenerator'
import LocalStorageService from '@/@service/utils/LocalStorage.service'
import PaymentCollectionService from '@/@service/api/accounting/PaymentCollection.service'
import Search from './components/Search.vue'
import FeeModal from './components/FeeModal.vue'
import ConcessionModal from './components/ConcessionModal.vue'
import AdjustmentModal from './components/AdjustmentModal.vue'
import SetPriorityModal from './components/SetPriorityModal.vue'
import PendingTransactionModal from './components/PendingTransaction.vue'
import SingleCollection from './components/SingleCollection.vue'
import BulkCollection from './components/BulkCollection.vue'
import ActivityModal from '../audit/ActivityModal.vue'

export default {
  components: {
    BCard,
    VueGoodTable,
    BPagination,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BButton,
    BRow,
    BCol,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    Invoice,
    RefreshCwIcon,
    BLink,
    // Preloader,
    // ActivityTimeline,
    Search,
    FeeModal,
    ConcessionModal,
    AdjustmentModal,
    SetPriorityModal,
    PendingTransactionModal,
    SingleCollection,
    BulkCollection,
    ActivityModal,
  },
  directives: {
    // 'b-modal': VBModal,
  },
  data() {
    return {
      getPageData: JSON.parse(LocalStorageService.get('pageData')) || '',
      totalDue: 0,
      collectionCoaId: 47,
      addFeeData: {
        installmentMonth: '',
        feeHead: '',
        amount: '',
        reason: '',
      },
      is_show: false,
      addFeeDataFeeHeadOptions: [],
      adjData: {
        operationType: [],
        amount: '',
        reason: '',
      },
      isAdjReason: false,

      adjDataOperationTypeOptions: [
        { value: 'add', name: 'Add' },
        { value: 'subtract', name: 'Subtract' },
      ],

      adjDataOperationTypeState: null,
      adjDataAmountState: null,
      adjDataReasonState: null,

      adjUpdateID: null,
      adjFeeHead: null,
      adjAmount: null,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Date Created',
          field: 'created_at',
        },
        {
          label: 'Date Modified',
          field: 'updated_at',
        },
        {
          label: 'Item Name',
          field: 'item_name',
          sortable: false,
        },
        {
          label: 'Due Amount',
          field: 'amount',
          type: 'decimal',
          tdClass: 'text-right',
          thClass: 'text-left',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      tempRows: [],
      submit: {
        collectionAmount: null,
        amount: '',
        ref_id: '',
      },
      collectionAmountOptions: [
        { id: 47, name: 'Cash' },
        { id: 'print', name: 'Print' },
      ],
      collectionAmountState: null,
      submitAmountState: null,
      collectionAmountButtonName: 'Collect Now',

      payNowID: null,
      invoiceSelected: 'first',
      invoiceOptions: [
        { text: 'Full Invoice', value: 'first' },
        { text: 'Partial Invoice', value: 'second' },
      ],

      printColumns: [
        {
          label: 'Name',
          field: 'item_name',
        },
        {
          label: 'Amount',
          field: 'amount',
          type: 'decimal',
          tdClass: 'text-right',
          thClass: 'text-left',
        },
      ],
      printRows: [],
      type: 1,
      idState: null,
      paymentCycleSessionId: null,
      stdFeeConfigSessionOptions: [],
      studentID: '',
      searchStudents: [],
      studentName: '',
      students: [],

      studentData: {
        full_name: '',
        className: '',
        id: '',
        sessionId: '',
        campusName: '',
        sessionName: '',
        classId: '',
        sectionName: '',
        branchId: '',
      },
      searchApplicant: [],
      applicantName: '',
      customPaymentColumns: [
        {
          label: 'Name',
          field: 'item_name',
        },
        {
          label: 'Amount',
          field: 'amount',
          type: 'decimal',
          tdClass: 'text-right',
          thClass: 'text-left',
        },
      ],
      customPaymentRows: [],
      invoiceData: {},
      studentInfo: {},
      invoiceInfo: {
        printData: '29-07-2021',
        printedBy: '2001254785',
        poweredBy: ' fitl.com.bd',
      },
      isPrintInvoice: false,
      radioChecked: 'PRINT',
      reference_id: null,
      collectionChecked: 47,
      collectionReferenceId: null,
      transactionColumns: [
        {
          label: 'Chart of ID',
          field: 'coa',
        },
        {
          label: 'Amount',
          field: 'amount',
        },
      ],
      transactionRows: [],
      transactionDetails: [],
      deleteReasonState: null,
      deleteReason: null,
      deleteId: null,
      fintechBank: null,
      fintechBankName: null,
      fintechBankOptions: [],
      tag: 'payment-collection',
      remainingAmount: 0,
      moneyReceipt: {},
      paymentInfo: {},
      transactionInfo: {},
      isFeeModal: false,
      isConcessionModal: false,
      isAdjustmentModal: false,
      isSetPriorityModal: false,
      isPendingTransactionModal: false,
      isSingleCollection: false,
      isBulkCollection: false,
      activityModal: false,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {

  },
  created() {
    setTimeout(() => {
      // this.getStdFeeConfigSession()
    }, 500)
  },
  mounted() {},
  methods: {

    // Select student from search component
    onSelectStudent(studentData, studentInfo) {
      this.studentID = studentData.id
      this.studentData = studentData
      this.studentInfo = studentInfo
      this.is_show = true
      this.getGridData()
    },
    // Student Remaining Advance
    getRemainingAdvance(studentId) {
      PaymentCollectionService.getRemainingAdvance(studentId)
        .then(res => {
          if (res.data.status) {
            this.remainingAmount = res.data.data.amount
          }
        })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
    },

    // Student Charge grid Data api
    getGridData() {
      this.getRemainingAdvance(this.studentData.id)

      if (this.remainingAmount && this.remainingAmount > 0) {
        this.adjDataOperationTypeOptions.push({ value: 'adj_adv', name: 'Adjust from Advance' })
      }

      PaymentCollectionService.getChargesData(this.studentID).then(res => {
        if (res.data.status) {
          this.rows = res.data.data
          if (this.rows.length < 1) {
            this.is_show = false
          }
        } else {
          this.rows = []
        }
        this.totalDueSum()
      })
        .catch(err => {
          this.rows = []
          FemsToastrService.error(err?.message)
        })
    },

    // Reset
    onReset() {
      this.is_show = false
      this.studentID = ''
      this.studentName = null
      this.rows = []
      this.paymentCycleSessionId = null
      this.searchStudents = []
      this.idState = null
      this.isPrintInvoice = false

      this.totalDue = 0
      this.addFeeData = {
        installmentMonth: '',
        feeHead: '',
        amount: '',
        reason: '',
      }
      this.addFeeDataFeeHeadOptions = []

      this.adjData = {
        operationType: [],
        amount: '',
        reason: '',
      }
      this.isAdjReason = false

      this.adjDataOperationTypeState = null
      this.adjDataAmountState = null
      this.adjDataReasonState = null

      this.adjUpdateID = null

      this.pageLength = 10
      this.dir = false
      this.rows = []
      this.tempRows = []

      this.submit = {
        collectionAmount: null,
        amount: '',
        ref_id: '',
      }
      this.collectionAmountState = null
      this.submitAmountState = null

      this.collectionAmountButtonName = 'Collect Now'

      this.payNowID = null

      this.invoiceSelected = 'first'

      this.printRows = []

      this.idState = null

      this.paymentCycleSessionId = null
      this.stdFeeConfigSessionOptions = []
      this.studentID = ''
      this.searchStudents = []
      this.studentName = ''
      this.students = []

      this.studentData = {
        full_name: '',
        className: '',
        id: '',
        sessionId: '',
        campusName: '',
        sessionName: '',
        classId: '',
        sectionName: '',
        branchId: '',
      }
      this.searchApplicant = []
      this.applicantName = ''
      this.customPaymentRows = []
      this.invoiceData = {}
      this.studentInfo = {}
      this.isPrintInvoice = false
      this.printCashRows = []
      this.reference_id = null
      this.collectionReferenceId = null
      this.transactionRows = []
      this.transactionDetails = []
      this.deleteReasonState = null
      this.deleteReason = null
      this.deleteId = null
      this.fintechBank = null
      this.fintechBankName = null
      this.fintechBankOptions = []
      this.isFeeModal = false
    },
    // Student or Applicant charge print
    onPrintStudentCharge() {
      const studentData = {
        campus: this.studentInfo.std_academic.branch.name,
        campusAddress: this.studentInfo.std_academic.branch.address,
        name: this.studentInfo.full_name,
        studentId: this.studentInfo.student_id,
        class: this.studentInfo.std_academic.student_class.name || '',
        section: this.studentInfo.std_academic.student_section
          ? this.studentInfo.std_academic.student_section.name
          : '',
        roll: this.studentInfo.std_academic.roll_no || '',
      }
      PaymentCollectionService.getPrintInvoiceData(this.studentID, {}).then(res => {
        if (res.data.status) {
          this.invoiceData = res.data.data
          this.isPrintInvoice = true
          FemsToastrService.success(res?.data.message)
          const invoiceInfo = [
            {
              studentData,
              ...this.invoiceData,
            },
          ]
          const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ]
          const date = new Date()
          generatePDF(
            this.getPageData,
            invoiceInfo,
            `${studentData.studentId}.${
              months[date.getMonth()]
            }-${date.getFullYear()}`,
          )
        } else {
          this.invoiceData = []
          FemsToastrService.error(res?.data.message)
        }
      })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
    },

    onPrint() {

    },

    onDelete(rowData) {
      this.deleteReasonState = null
      this.deleteReason = null
      this.$bvModal.show('delete-reason-model')
      this.deleteId = rowData.id
    },

    onDeleteValidation() {
      this.deleteReasonState = null
      if (this.deleteReason === null || this.deleteReason === '') {
        this.deleteReasonState = false
        return false
      }
      return true
    },
    // TODO:: Currently not working
    onDeleteCharge() {
      if (!this.onDeleteValidation()) {
        return
      }
      this.$swal({
        title: 'Are you sure to delete?',
        text: "You won't be able to revert this!",
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          // TODO:: Currently not working and route not implemented
          PaymentCollectionService.onDeleteCharge(this.deleteId, {
            note: this.deleteReason,
          }).then(res => {
            if (res.data.status) {
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: res.data.message,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.getGridData()
              this.deleteId = null
              this.deleteReason = null
              this.$bvModal.hide('delete-reason-model')
            } else {
              FemsToastrService.error(res?.data.message)
            }
          })
        }
      })
    },

    onAddFee() {
      this.isFeeModal = !this.isFeeModal
      setTimeout(() => {
        this.$bvModal.show('add-fee-modal')
      }, 50)
    },

    onPayNow(rowData) {
      this.isSingleCollection = true
      setTimeout(() => {
        this.$refs.singleCollection.onCollectListView(rowData, this.collectionCoaId)
      }, 50)
    },

    onCustomPaymentPriority() {
      this.isSetPriorityModal = true
      setTimeout(() => {
        this.$refs.priorityModal.onCustomPaymentPriority()
      }, 50)
    },
    // concession Functions
    getConcession(rowData) {
      this.isConcessionModal = true
      setTimeout(() => {
        this.$refs.concessionModal.getConcession(rowData)
      }, 50)
    },

    onSubmit() {
      let errorMessage = null
      if (!this.submit.amount || this.submit.amount === '') {
        errorMessage = 'Please Enter Amount'
      }
      if (errorMessage) {
        FemsToastrService.error(errorMessage)

        return
      }
      this.isBulkCollection = true
      setTimeout(() => {
        this.$refs.BulkCollection.onSubmitCollectListView(this.submit.amount)
      }, 50)
    },

    refreshGrid() {
      this.getGridData()
    },

    onAdjustment(rowData) {
      this.isAdjustmentModal = true
      setTimeout(() => {
        this.$refs.adjustmentModal.onAdjustment(rowData)
      }, 50)
    },

    onSaveCollectionGrid() {
      this.printCashRows = []
      PaymentCollectionService.saveCollectionGrid(this.studentData.id, {
        amount: this.submit.amount,
      })
        .then(res => {
          if (res.data.status) {
            this.printCashRows = res.data.data
          } else {
            FemsToastrService.error(res?.data.message)
          }
        })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
        .then(res => {
          if (res.data.status) {
            this.printCashRows = res.data.data
          } else {
            this.printCashRows = []
          }
        })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
      this.$bvModal.show('onSubmitListOpenModel')
      this.$bvModal.hide('onSubmitConfrmModal')
    },

    // onSavePaymentAdjustment() {
    //   PaymentCollectionService.savePaymentAdjustment(this.studentData.id, {
    //     amount: this.submit.amount,
    //     collectionCoaId: this.collectionCoaId,
    //   })
    //     .then(res => {
    //       if (res.data.status) {
    //         this.$bvModal.hide('submit-modal')
    //         this.submit = {
    //           collectionAmount: null,
    //           amount: '',
    //         }
    //         FemsToastrService.success(res?.data.message)
    //         this.getGridData()
    //       } else {
    //         FemsToastrService.error(res?.data.message)
    //       }
    //     })
    //     .catch(err => {
    //       FemsToastrService.error(err?.message)
    //     })
    // },

    pendingTransactionDetails() {
      this.isPendingTransactionModal = true
      setTimeout(() => {
        this.$refs.pendingTransactionModal.onModalOpen()
      }, 50)
    },

    totalDueSum() {
      let total = 0
      this.rows.forEach(item => {
        total += item.amount
      })
      this.totalDue = total
    },

    // Audit Activity
    onActivityLog() {
      this.activityModal = true
      this.$nextTick(() => {
        this.$refs.activityModal.modalShow()
      })
    },

  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-autosuggest.scss";
input:focus {
  outline: none;
  border: 1px solid red;
}
.custom-font-size {
  font-size: 11px;
}
.dragable-class {
  font-size: 15px;
  padding: 10px;
  background: lightgray;
  margin-bottom: 10px;
  cursor: pointer;
  list-style-type: none;
}
.right-span {
  float: right;
}
.dot {
  width: 5px;
  height: 5px;
  background: grey;
  border-radius: 50%;
  margin-bottom: 1px;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
svg.feather.feather-refresh-cw {
  height: 15px;
  width: 15px;
}
.due_table {
  border: none;
}
#printTable {
  border: 1px solid gray;
}
#printTable td,
th {
  border: 1px solid gray;
}
#printTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 5px;
}
.right-align {
  text-align: right;
  width: 200px;
}
</style>
