<template>
  <div>
    <!-- concession Modal -->
    <b-modal
      id="concession-modal"
      no-close-on-backdrop
      title="Fee concession"
      ok-title="Process"
      ok-variant="outline-primary"
      cancel-variant="outline-danger"
      @ok.prevent="concessionReason"
    >
      <b-form @submit.prevent>
        <div class="text-center">
          <b>Student Name: {{ studentData.name }}. Student ID:
            {{ studentData.id }}</b>
        </div>
        <br>
        <div class="text-center mb-2">
          <b>Fee Head:</b> {{ consessionFeeHead }} <br>
          <b> Amount:</b> {{ concessionAmount }}
        </div>

        <!-- Coa Mapping dropdown -->
        <b-row v-if="concessionCoaState === true">
          <b-col>
            <b-form-group
              label="Concession Coa List"
              label-cols-md="4"
              invalid-feedback="Concession Data is required"
              :state="concessionDataCoaFilterState"
            >
              <v-select
                v-model="concessionData.coaFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="gl_name"
                placeholder="Select COA Item"
                :options="concessionCoaOptions"
                :reduce="(val) => val.id"
                :state="concessionDataCoaFilterState"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Amount -->
        <b-row>
          <b-col>
            <b-form-group
              label="Amount"
              label-cols-md="4"
              invalid-feedback="Amount is required"
              :state="concessionDataAmountState"
            >
              <b-form-input
                v-model="concessionData.amount"
                placeholder="Amont"
                :state="concessionDataAmountState"
                required
                type="number"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>

    <!-- Concession Modal Reason -->
    <b-modal
      id="concession-modal-reason"
      no-close-on-backdrop
      title="concession Reason"
      ok-title="Yes"
      cancel-title="No"
      ok-variant="outline-success"
      cancel-variant="outline-danger"
      @ok.prevent="onSaveConcession"
    >
      <b-form @submit.prevent>
        <div class="text-center">
          <b>Do you want to process this concession??</b>
        </div>
        <br>
        <div class="text-center mb-2">
          <b>Student Name: {{ studentData.name }}. Student ID:
            {{ studentData.id }}</b>
        </div>

        <!-- Reason -->
        <b-row>
          <b-col>
            <b-form-group
              invalid-feedback="Reason is required"
              :state="concessionDataReasonState"
            >
              <b-form-textarea
                id="textarea"
                v-model="concessionData.reason"
                placeholder="Reason (Require)"
                rows="3"
                max-rows="6"
                :state="concessionDataReasonState"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>
<script>

import {
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea,
  VBModal,
} from 'bootstrap-vue'
import 'vue-good-table/dist/vue-good-table.css'
import vSelect from 'vue-select'
// import store from '@/store/index'
import FemsToastrService from '@/@service/utils/FemsToastr.service'
// import LocalStorageService from '@/@service/utils/LocalStorage.service'
import PaymentCollectionService from '@/@service/api/accounting/PaymentCollection.service'

export default {
  components: {
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BFormTextarea,
  },
  directives: {
    'b-modal': VBModal,
  },

  props: {
    studentData: {
      type: Object,
      required: true,
    },
    getGridData: {
      type: Function,
      required: true,
    },
    isConcessionModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      concessionData: {
        amount: '',
        reason: '',
        coaFilter: '',
      },
      concessionDataAmountState: '',
      concessionDataReasonState: '',
      concessionDataCoaFilterState: '',
      concessionCoaOptions: [],
      concessionCoaState: true,
      concessionFeeHead: '',
      concessionAmount: '',
      consessionFeeHead: '',
    }
  },

  methods: {
    concessionReason() {
      if (!this.concessionValidation()) {
        return
      }
      this.$bvModal.show('concession-modal-reason')
      this.concessionDataReasonState = null
    },

    onSaveConcession() {
      if (!this.concessionReasonValidation()) {
        return
      }
      // eslint-disable-next-line no-unused-vars
      const params = {
        amount: this.concessionData.amount,
        note: this.concessionData.reason,
        concessionId: this.concessionData.coaFilter || null,
      }

      PaymentCollectionService.saveConcession(this.concessionUpdateID, params).then(res => {
        const response = res.data
        if (response.status) {
          FemsToastrService.success(res?.data?.message)
          this.$bvModal.hide('concession-modal')
          this.$bvModal.hide('concession-modal-reason')
          this.concessionUpdateID = null
          this.getGridData()
          return
        }
        FemsToastrService.error(res?.data?.message)
      })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
    },

    onCancelModal() {
      this.$emit('update:isConcessionModal', false)
      this.$bvModal.hide('concession-modal')
    },

    concessionValidation() {
      this.concessionDataAmountState = null
      this.concessionDataCoaFilterState = null

      if (this.concessionCoaState === true) {
        if (
          this.concessionData.coaFilter === ''
          || this.concessionData.coaFilter === null
        ) {
          this.concessionDataCoaFilterState = false
          return false
        }
      }
      if (
        this.concessionData.amount === ''
        || this.concessionData.amount === null
      ) {
        this.concessionDataAmountState = false
        return false
      }
      return true
    },

    concessionReasonValidation() {
      this.concessionDataReasonState = null
      if (!this.concessionData.reason) {
        this.concessionDataReasonState = false
        return false
      }
      return true
    },

    getConcession(rowData) {
      this.concessionData.coaFilter = null
      this.concessionData.amount = null
      this.concessionData.reason = null
      this.concessionDataAmountState = null
      this.concessionDataCoaFilterState = null
      this.concessionDataCoaReasonState = null
      this.concessionCoaId = null
      this.concessionUpdateID = rowData.id
      this.consessionFeeHead = rowData.item_name
      this.concessionAmount = rowData.amount
      this.concessionCoaState = true
      this.concessionCoaOptions = []

      if (rowData.coa_id) {
        PaymentCollectionService.getConcession(rowData.coa_id).then(res => {
          if (res.data.status) {
            this.concessionCoaOptions = res.data.data
            this.concessionCoaState = !!this.concessionCoaOptions.length
            this.$bvModal.show('concession-modal')
            this.isConcessionReason = true
            return
          }
          FemsToastrService.error('No concession found')
        })
      }
    },
    concessionReset() {
      this.consessionData = {
        amount: '',
        reason: '',
      }
      this.isconsessionReason = false

      this.consessionDataAmountState = null
      this.consessionDataReasonState = null
      /* variable for consession */
    },
  },

}
</script>
