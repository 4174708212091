<template>
  <div class="custom-search d-flex">
    <b-form-group
      label-for="pay-cycle-config-session"
      class="pr-0 col-lg-3 col-md-3"
    >
      <v-select
        v-model="type"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        placeholder="Select Type"
        :options="typeOptions"
        :reduce="(val) => val.id"
        disabled
      />
    </b-form-group>
    <!-- Search for Student ID  -->
    <b-form-group
      v-if="type == 1"
      class="pr-0 col-lg-3 col-md-3"
      invalid-feedback="Student Id is required"
      :state="idState"
    >
      <vue-autosuggest
        v-model="studentIdState"
        :suggestions="[{data:searchStudents}]"
        :limit="10"
        :input-props="{
          id: 'autosuggest__input',
          class: 'form-control',
          placeholder: 'Search Student ID',
        }"
        :get-suggestion-value="getSuggestionValueForStudent"
        type="number"
        @selected="onSelectedStudent"
        @input="onInputChangeStudent"
      >
        <template slot-scope="{ suggestion }">
          <span class="my-suggestion-item">
            {{ suggestion.item.full_name }} ({{
              suggestion.item.student_id
            }})<br>
            <span class="custom-font-size">
              Class : {{ suggestion.item.std_academic.student_class.name }}
              <span
                v-if="suggestion.item.std_academic.student_section"
              >({{
                suggestion.item.std_academic.student_section.name
              }})</span>
              <span v-else>(N/A)</span>, Roll :
              <span v-if="suggestion.item.std_academic.roll_no">{{
                suggestion.item.std_academic.roll_no
              }}</span>
              <span v-else>(N/A)</span>,
              {{ suggestion.item.std_academic.branch.name }}
              ({{ suggestion.item.std_academic.secondary_session.name }})
            </span>
          </span>
        </template>
      </vue-autosuggest>
    </b-form-group>

    <b-form-group
      label-sr-only
      label="Buttons"
      class="ml-5"
    >
      <b-button
        type="submit"
        variant="outline-success"
        class="mr-1"
        :disabled="studentIdState ? false : true"
        @click.prevent="onSearch"
      >
        Search
      </b-button>
      <b-button
        type="submit"
        variant="outline-danger"
        class="mr-1"
        :disabled="studentIdState ? false : true"
        @click.prevent="onClear"
      >
        Clear
      </b-button>
    </b-form-group>
  </div>
</template>
<script>
import {
  BFormGroup,
  BButton,
} from 'bootstrap-vue'

import 'vue-good-table/dist/vue-good-table.css'
import vSelect from 'vue-select'
import { VueAutosuggest } from 'vue-autosuggest'
import store from '@/store/index'

import FemsToastrService from '@/@service/utils/FemsToastr.service'
import PaymentCollectionService from '@/@service/api/accounting/PaymentCollection.service'

export default {
  components: {
    BFormGroup,
    vSelect,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    VueAutosuggest,
  },
  props: {
    onReset: {
      type: Function,
      required: true,
    },
    onSelectStudent: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      dir: false,
      type: 1,
      idState: null,
      typeOptions: [
        {
          id: 1,
          name: 'Student',
        },
        {
          id: 2,
          name: 'Applicant',
        },
      ],
      searchApplicant: [],
      searchStudents: [],
      studentName: null,
      studentIdState: null,
      studentID: null,
      studentData: {},
      isShow: false,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  methods: {
    // Student suggestion
    getSuggestionValueForStudent(suggestion) {
      return suggestion.item.student_id
    },
    // Student or Applicant suggestion
    onInputChangeStudent(search) {
      if (!search) {
        this.searchStudents = []
        this.isShow = false
        return
      }

      this.onClear()
      this.studentIdState = search

      if (search && search.length > 10) {
        this.idExceed = true
        this.searchStudents = []
        this.isShow = false
        return
      }

      if (search.length && search.length >= 4 && search.length <= 10) {
        if (search && String(Number(search)) === 'NaN') {
          this.onClear()
          FemsToastrService.info('Please enter a valid Student ID')
          return
        }

        if (Number(search.substring(0, 1)) !== 1) {
          this.onClear()
          FemsToastrService.info('Please enter a valid Student ID')
          return
        }

        this.idExceed = false
        PaymentCollectionService.getStudentList(search)
          .then(res => {
            if (res.data.status && res.data.data.length) {
              this.searchStudents = res.data.data
              return
            }
            this.isShow = false
            this.searchStudents = []
            this.onClear()
          })
          .catch(err => {
            FemsToastrService.error(err?.message)
            this.onClear()
          })
      } else {
        this.searchStudents = []
        this.isShow = false
      }
    },

    // Selected Student
    onSelectedStudent(option) {
      this.onClear()
      const stdInfo = option.item
      this.studentID = stdInfo.student_id
      this.studentIdState = stdInfo.student_id.toString()
      this.studentName = `${stdInfo.student_id}`
      this.idState = null
      this.studentData.name = stdInfo.full_name
      this.studentData.id = stdInfo.student_id
      this.studentData.classId = stdInfo.std_academic.class_id
      this.studentData.className = stdInfo.std_academic.student_class.name
      this.studentData.campusName = stdInfo.std_academic.branch.name
      this.studentData.branchId = stdInfo.std_academic.branch_id
      this.studentData.sessionId = stdInfo.std_academic.secondary_session.id
      this.studentData.sessionName = stdInfo.std_academic.secondary_session.name
      this.studentInfo = stdInfo
      if (this.studentData.id) {
        this.onSearch()
      }
    },
    // Id validation
    idValidate() {
      this.idState = null
      if (
        this.studentID === ''
        || this.studentID === null
        || this.studentName === ''
        || this.studentName === null
      ) {
        this.idState = false
        return false
      }
      return true
    },
    // Student or Applicant Search
    onSearch() {
      if (!this.idValidate()) {
        return
      }
      if (!this.studentIdState) {
        this.onClear()
        return
      }
      if (this.idExceed) {
        this.is_show = false
        this.onClear()
        return
      }
      this.is_show = true
      this.idExceed = false
      this.onSelectStudent(this.studentData, this.studentInfo)
    },
    onClear() {
      this.onReset()
      this.studentIdState = null
      this.searchStudents = []
    },
  },
}
</script>
